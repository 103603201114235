import React from 'react';
import logo from '../../../assets/images/logo-Selfridges_logo.svg';
import Container from '../UIElements/ContainerFluid';
import './MainHeader.css';
import TickerTape from './TickerTape';

const MainHeader = () => {
	return (
		<React.Fragment>

			<Container>
				<header className="header">

					<div className="logo">
						<a href="https://www.selfridges.com/">
							<img
								className="fill align-self-center logo"
								src={logo}
								alt="logo"
								height="27px"
								width="177"
							/>
						</a>
					</div>

				</header>
			</Container>
			<TickerTape />
		</React.Fragment>
	);
};

export default MainHeader;
