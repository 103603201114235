import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Backdrop from './Backdrop';
import './Modal.css';
import close from '../../../assets/images/ic-close.svg';

const Modal = props => {
	const nodeRef = React.useRef(null);

	const content = (
		<div id='modal-hook'>
			{props.show && <Backdrop onClick={props.onCancel} />}
			<CSSTransition
				nodeRef={nodeRef}
				in={props.show}
				mountOnEnter
				unmountOnExit
				timeout={200}
				classNames="modal"
			>
				<div ref={nodeRef} className={`modal ${props.className}`} style={props.style}>
					<header className={`modal__header ${props.headerClass}`}>
       
					</header>
					<form
						onSubmit={
							props.onSubmit ? props.onSubmit : event => event.preventDefault()
						}
					>
						<img src={close} alt="close" className="button-close" width="24px" onClick={props.onCancel}/>
    
						{props.children}
  
					</form>
				</div>
			</CSSTransition>
		</div>
	);

	return ReactDOM.createPortal(content, document.body);
};

export default Modal;
