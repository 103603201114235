
export const brandMapping = (value) => {

	const responseData = [
		{
			'id': 'fd4f3f2f-b7e8-4d3d-b69f-7af531baab2c',
			'name': 'Alexander McQueen'
		},
		{
			'id': '180099e8-b5dd-4b68-b470-58097001d5ea',
			'name': 'Alexander Wang'
		},
		{
			'id': '9fb3dab1-cb26-4450-a205-44f4129995a7',
			'name': 'Balenciaga'
		},
		{
			'id': '1df39822-2088-49a5-902d-e0396654c98b',
			'name': 'Balmain'
		},
		{
			'id': '348cfc98-530d-4027-a625-70e49943b92d',
			'name': 'Bottega Veneta'
		},
		{
			'id': '3484d002-933b-440d-bd55-c499ba70f05c',
			'name': 'Burberry'
		},
		{
			'id': 'd4b1a77f-4e63-4c56-ae0f-f6487c3691fa',
			'name': 'Bulgari'
		},
		{
			'id': 'af10788e-d194-4450-aa40-84be70cf4195',
			'name': 'Celine'
		},
		{
			'id': 'b0dd1a5f-5dd8-4109-9cc6-55a4ca8f436a',
			'name': 'Chanel'
		},
		{
			'id': '81cb9d93-914b-474b-af6c-8a18b3413ddf',
			'name': 'Dior'
		},
		{
			'id': '288be31f-9c33-43a8-ad66-a9deb591834e',
			'name': 'Dolce & Gabbana'
		},
		{
			'id': '357cc348-7d4a-4d85-bd48-cfec8aa25e60',
			'name': 'Fendi'
		},
		{
			'id': 'f97269e1-c089-4533-b30b-c8189fb0d3e2',
			'name': 'Givenchy'
		},
		{
			'id': 'f4e60000-3a25-000d-6fb2-08d4de4b5a86',
			'name': 'Goyard'
		},
		{
			'id': '4753146f-d10b-41a1-a54c-341ad9b0e6e8',
			'name': 'Gucci'
		},
		{
			'id': '618d52c0-3ae9-4e82-b1f8-8a64fbba5934',
			'name': 'Hermès'
		},
		{
			'id': '421c0c25-0196-42ca-a3b1-6b34aa6b6482',
			'name': 'Jacquemus'
		},
		{
			'id': '96877c8e-d11e-4672-878d-dfd04b054d57',
			'name': 'Loewe'
		},
		{
			'id': 'e97aaadc-536b-489f-a0d4-8f6ea701a5da',
			'name': 'Louis Vuitton'
		},
		{
			'id': '77360915-331d-4fff-8cb4-a9fd83f86fb4',
			'name': 'MCM'
		},
		{
			'id': '46c445b5-5606-416b-931a-48d68124e4ad',
			'name': 'Miu Miu'
		},
		{
			'id': '90b40b14-4f80-4108-8896-4f0d38c6819c',
			'name': 'Mulberry'
		},
		{
			'id': 'fb451fc0-6445-4350-8ae0-127ca0fd473e',
			'name': 'Off-White'
		},
		{
			'id': 'be19b63c-ab65-4455-9697-68fd22f2c1f4',
			'name': 'Prada'
		},
		{
			'id': '3fb4dc67-42ac-4812-a6a8-a822cca1d58d',
			'name': 'Salvatore Ferragamo'
		},
		{
			'id': '562b00db-2788-4905-8eb0-aea5a2c549b3',
			'name': 'Stella McCartney'
		},
		{
			'id': '3d32b668-7975-4596-b169-0f111d0e0780',
			'name': 'The Row'
		},
		{
			'id': '64aa885f-5cb6-4a31-bffd-45a2b5636b28',
			'name': 'Valentino'
		},
		{
			'id': 'd590f7db-5c2f-4496-b09e-b647dac8f94a',
			'name': 'Versace'
		},
		{
			'id': '21022dd7-38a1-4796-bc1a-bf61f5a4aa23',
			'name': 'Saint Laurent'
		}
	];
	const filteredData = responseData.filter(brand => brand.name === value);

	return filteredData[0].id;

};

export const sizesMapping = (size) => {

	if(size==='Extra Small') {
		return 'XS';
	}

	if(size==='Small') {
		return 'S';
	}

	if(size==='Medium') {
		return 'M';
	}

	if(size==='Large') {
		return 'L';
	}

	if(size==='Extra Large') {
		return 'XL';
	}
};

export const conditionsMapping = (condition) => {

	switch (condition) {
		case 'Pristine': return 'A+';
		case 'Excellent': return 'A';
		case 'Very Good': return 'AB';
		case 'Good': return 'B+';
		case 'Used': return 'B';
		case 'Poor': return 'BC';
	}
};

export const countryList = [
	{ name: 'Austria', dialCode: '43', code: 'AT' },
	{ name: 'Belgium', dialCode: '32', code: 'BE' },
	{ name: 'Bulgaria', dialCode: '359', code: 'BG' },
	{ name: 'Croatia', dialCode: '385', code: 'HR' },
	{ name: 'Cyprus', dialCode: '537', code: 'CY' },
	{ name: 'Czech Republic', dialCode: '420', code: 'CZ' },
	{ name: 'Denmark', dialCode: '45', code: 'DK' },
	{ name: 'Estonia', dialCode: '372', code: 'EE' },
	{ name: 'Finland', dialCode: '358', code: 'FI' },
	{ name: 'France', dialCode: '33', code: 'FR' },
	{ name: 'Germany', dialCode: '49', code: 'DE' },
	{ name: 'Greece', dialCode: '30', code: 'GR' },
	{ name: 'Hungary', dialCode: '36', code: 'HU' },
	{ name: 'Ireland', dialCode: '353', code: 'IE' },
	{ name: 'Italy', dialCode: '39', code: 'IT' },
	{ name: 'Latvia', dialCode: '371', code: 'LV' },
	{ name: 'Lithuania', dialCode: '370', code: 'LT' },
	{ name: 'Luxembourg', dialCode: '352', code: 'LU' },
	{ name: 'Malta', dialCode: '356', code: 'MT' },
	{ name: 'Netherlands', dialCode: '31', code: 'NL' },
	{ name: 'Poland', dialCode: '48', code: 'PL' },
	{ name: 'Portugal', dialCode: '351', code: 'PT' },
	{ name: 'Romania', dialCode: '40', code: 'RO' },
	{ name: 'Slovakia', dialCode: '421', code: 'SK' },
	{ name: 'Slovenia', dialCode: '386', code: 'SI' },
	{ name: 'Spain', dialCode: '34', code: 'ES' },
	{ name: 'Sweden', dialCode: '46', code: 'SE' },
	{ name: 'United Kingdom', dialCode: '44', code: 'GB' },
];

export const countryMapping = (countryName) => {
	return countryList.find(country => country.name === countryName);
};
