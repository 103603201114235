import React, { useEffect, useReducer, useState } from 'react';
import { validate } from '../../util/validator';
import './Input.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':

			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators)
			};
		case 'TOUCH': {
			return {
				...state,
				isTouched: true
			};
		}
		default:
			return state;
	}
};

const Input = props => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isTouched: false,
		isValid: props.initialValid || false
	});

	const [count, setCount] = useState(450);

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		if (onInput) {
			onInput(id, value, isValid);
		}
	}, [id, value, isValid, onInput]);

	const changeHandler = event => {
		dispatch({
			type: 'CHANGE',
			val: event.target.value,
			validators: props.validators
		});
		setCount(450 - event.target.value.length);
	};

	const touchHandler = () => {
		dispatch({
			type: 'TOUCH'
		});
	};

	return (
		<div
			className={`form-control ${!inputState.isValid && inputState.isTouched &&
        'form-control--invalid'}`}
		>
			<label htmlFor={props.id}>{props.label}</label>
			{props.element === 'input'
				? (
					<input
						id={props.id}
						type={props.type}
						placeholder={props.placeholder}
						onChange={changeHandler}
						onBlur={touchHandler}
						value={inputState.value}
					/>
				) : (
					<React.Fragment>
						<textarea
							id={props.id}
							rows={props.rows || 3}
							onChange={changeHandler}
							onBlur={touchHandler}
							data-gramm="false"
							value={inputState.value}
						/>
						<div className="d-flex">
							<div className="ml-auto">{count} characters remaining</div>
						</div>
					</React.Fragment>
				)}
			{!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
		</div>
	);
};

export default Input;
