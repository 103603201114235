import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import Loading from '../../components/Loading/Loading';
import './AdditionalPhotosConfirmation.css';

export default function AdditionalPhotosConfirmation() {

	const [state, setState] = useState({});
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const paths = ['Offer Requested', 'Images Received', 'Offer Given'];
	const pathIndex = 1;

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');
		const id = params.get('id');
		const itemId = params.get('itemId');

		if (token && id && itemId) {
			getPageData(id, token, itemId);
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function getPageData(id, token, itemId) {
		try {
			const options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			};

			const response = await fetch(`/submissions/get?token=${token}&id=${id}`, options);
			if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const responseData = await response.json();

			const item = responseData.items.find((item) => item.id === itemId);

			if (item.status !== 'MoreInformationReceived') {
				return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
			}

			setState({
				order: responseData.externalReference
			});
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
		}
	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return(
		<>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-684">
				<h1 className="cta-h1-titles">THANK YOU, YOUR IMAGES HAVE BEEN UPLOADED SUCCESSFULLY</h1>
				<p><strong>So, what happens now?</strong></p>
				<p>
					Well, a team of valuation experts will carry out a thorough evaluation of your bag, including an
					assessment of its authenticity, resale value, condition and the demand for that particular bag style.
					(This normally takes around two-to-three working days).
				</p>
				<p>
						If it get a big yellow tick from them, we&apos;ll email you with a quote (within 4-5 working days), which
						you can then accept or decline. If you accept, we&apos;ll arrange for your bag to be collected at a time
						that suits you (just to note: you will need to package up the bag yourself and print out the shipping label*).
				</p>
				<p>
						Once your bag has been approved, you&apos;ll receive the quoted amount in a Sellfridges eGift Card to spend in
						store or at selfridges.com. If your bag is not selected for resale, we&apos;ll email you with an explanation as
						to why it didn&apos;t make the cut on this occasion.
				</p>
				<p>
					Thanks again for using our service.
				</p>
				<p><strong>RESELFRIDGES</strong></p>
			</div>
		</>
	);
}
