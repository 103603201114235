import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import './OfferAccepted.css';

let id = '';
let token = '';
let itemId = '';
let collectionId = '';

export default function OfferAccepted() {

	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [redirectOfferDeclined, setRedirectOfferDeclined] = useState(false);
	const [redirectQuoteExpired, setRedirectQuoteExpired] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		token = params.get('token');
		id = params.get('id');

		if(token && id) {
			initPage();
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function initPage() {
		const offer = await getOffer();

		const { status } = offer;

		switch (status) {
			case 'Submitted': {
				const collectionId = await acceptOffer();
				getPageData(offer, collectionId);
				break;
			}

			case 'Accepted':
				getPageData(offer);
				break;

			case 'Rejected':
				setRedirectOfferDeclined(true);
				break;

			case 'Timedout':
				setRedirectQuoteExpired(true);
				break;

			default:
				return setIsQueryParamsValid(false);
		}

	}

	async function getOffer() {
		const options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		};

		var response = await fetch(`/offers/get?token=${token}&id=${id}`, options);
		if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
			window.location.href = '/Maintenance';
		}
		return await response.json();
	}

	async function acceptOffer() {
		const options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		};

		const response = await fetch(`/offers/approve?token=${token}&id=${id}`, options);
		if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
			window.location.href = '/Maintenance';
		}

		if (response.status >= 400) {
			return setIsQueryParamsValid(false);
		}
		const responseData = await response.json();
		return responseData.collectionId;
	}

	async function getPageData(offer, createdCollectionId) {
		try {
			const options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			};

			itemId = offer.item.id;

			if (createdCollectionId) {
				collectionId = createdCollectionId;
			} else {
				const collectionResponse = await fetch(`/collections/getByItemExternalId?token=${token}&externalId=${offer.item.externalId}`, options);
				if (collectionResponse.status === 503 && collectionResponse.json().then((data) => data.reason === "maintenance")) {
					window.location.href = '/Maintenance';
				}

				if (collectionResponse.status >= 400) {
					return setIsQueryParamsValid(false);
				}
				const collection = await collectionResponse.json();

				if (collection.items.length > 1) {
					return setIsQueryParamsValid(false);
				}

				collectionId = collection.items[0].id;
			}

			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsQueryParamsValid(false);
		}

	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (redirectOfferDeclined) {
		return <Redirect to={`/OfferDeclined?token=${token}&id=${id}`} />;
	}

	if (redirectQuoteExpired) {
		return <Redirect to={`/QuoteExpired?token=${token}&id=${id}`} />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return <Redirect to={`/PickUpRequest?token=${token}&id=${collectionId}&itemId=${itemId}`} />;
}
