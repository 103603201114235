import React from 'react';
import ReactGa from 'react-ga';
import { useHistory } from 'react-router-dom';
import './Button.css';

const Button = props => {
	const history = useHistory();
	const linkClickHandler = () => {
		ReactGa.event({
			category: props.category,
			action: props.action,
			label: props.label
		});
		history.push(props.to);
	};

	if (props.to) {
		return (
			<button className={`button ${props.className}`} type="button" onClick={linkClickHandler}>
				{props.children}
			</button>
		);
	}

	if (props.href) {
		return (
			<a className={`button ${props.className}`} type="button" href={props.href}>
				{props.children}
			</a>
		);
	}

	return(
		<button
			className={`button ${props.className}`}
			type={props.type}
			onClick={props.onClick}
			disabled={props.disabled}>
			{props.children}
		</button>
	);

};

export default Button;
