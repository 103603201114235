import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import DetailContainer from '../../components/DetailContainer/DetailContainer';
import Loading from '../../components/Loading/Loading';
import Button from '../../shared/components/UIElements/Button';
import './OfferDeclined.css';

let id = '';
let token = '';

export default function OfferDeclined() {

	const [state, setState] = useState({});
	const [redirectOfferAccepted, setRedirectOfferAccepted] = useState(false);
	const [redirectQuoteExpired, setRedirectQuoteExpired] = useState(false);
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const paths = ['Offer Requested', 'Offer Declined'];
	const pathIndex = 1;

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		token = params.get('token');
		id = params.get('id');

		if(token && id) {
			initPage();

		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function initPage() {
		const offer = await getOffer();

		const { item, status } = offer;

		switch (status) {
			case 'Submitted':
				await declineOffer();
				getPageData(item.submissionId, item.id, token);
				break;

			case 'Rejected':
				getPageData(item.submissionId, item.id, token);
				break;

			case 'Accepted':
				setRedirectOfferAccepted(true);
				break;

			case 'Timedout':
				setRedirectQuoteExpired(true);
				break;

			default:
				return setIsQueryParamsValid(false);
		}

	}

	async function getOffer() {
		const options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		};

		var response = await fetch(`/offers/get?token=${token}&id=${id}`, options);
		if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
			window.location.href = '/Maintenance';
		}
		return await response.json();
	}

	async function declineOffer() {
		var body = {
			rejectionReason: 'No Rejection Reason Provided by Customer'
		};

		const options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body)
		};

		const response = await fetch(`/offers/reject?token=${token}&id=${id}`, options);
		if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
			window.location.href = '/Maintenance';
		}
	}

	async function getPageData(submissionId, submissionItemId, token) {

		try {
			const options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			};

			const response = await fetch(`/submissions/get?token=${token}&id=${submissionId}`, options);
			if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const submission = await response.json();

			const item = submission.items.find((item) => item.id === submissionItemId);

			setState({
				name: submission.customerInformation.name,
				images: item.assets,
				brand: item.brand.name,
				order: submission.externalReference
			});

			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsQueryParamsValid(false);
		}
	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (redirectOfferAccepted) {
		return <Redirect to={`/OfferAccepted?token=${token}&id=${id}`} />;
	}

	if (redirectQuoteExpired) {
		return <Redirect to={`/QuoteExpired?token=${token}&id=${id}`} />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return (
		<>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-1050">
				<div className="row padding-x-15">
					<div className="col-lg-6 col-12 margin-bottom-30">
						<p>Hello {state.name},</p>
						<p>
              Thanks again for sharing your bag with us on RESELFRIDGES.
              We&apos;re sorry you have decided not to sell it with us on this occasion.
						</p>
						<p>
              If you would like to share another bag with us, simply upload it below and
              we&apos;d be happy to take a look at it.
						</p>
						<Button
							to="/QuoteCreation"
							category="CTA Button Clicked"
							action="Sell another bag"
							label="Offer declined - Sell another bag"
							className="background-primary btt-w-240">Sell another bag</Button>
						<p>
              If you have any further question, please email us on <u><a href="mailto:reselfridges@selfridges.co.uk">
              reselfridges@selfridges.co.uk</a></u> quoting your reference number #{state.order}.
						</p>
						<p>We hope to see you again soon,</p>
						<p><strong>RESELFRIDGES</strong></p>
					</div>
					<div className="col-lg-6 col-12 margin-bottom-30">
						<DetailContainer
							images={state.images}
							brand={state.brand} />
					</div>
				</div>
			</div>
		</>
	);
}
