import React, { useState } from 'react';
import { validate } from '../../shared/util/validator';

export default function Select(props) {
	const { id, label, validators, setter, field, placeholder, errorText, options, required, disabled } = props;
	const [isValid, setIsValid] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);

	function validateField() {
		setIsValid(validate(field, validators));
		setHasChanged(true);
	}

	return (
		<div className={`form-control ${hasChanged && !isValid && 'form-control--invalid'}`}>
			<label htmlFor={id}>{label}{required && ' *'}</label>
			<select
				id={id}
				disabled={disabled}
				onChange={event => setter(event.target.value)}
				onBlur={validateField}
				value={field} >
				<option value="" disabled defaultValue>{placeholder}</option>
				{options.map((option, index) => <option key={index} value={option}>{option}</option>)}
			</select>
			{hasChanged && !isValid ? <p>{errorText}</p> : <></>}
		</div>
	);
}
