import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import DetailContainer from '../../components/DetailContainer/DetailContainer';
import Loading from '../../components/Loading/Loading';
import Button from '../../shared/components/UIElements/Button';
import './MissedPickUp.css';

let id = '';
let itemId = '';
let token = '';

export default function MissedPickUp() {

	const [state, setState] = useState({});
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const paths = ['Offer Requested', 'Offer Given', 'Re-schedule Pick-up'];
	const pathIndex = 2;

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		token = params.get('token');
		id = params.get('id');
		itemId = params.get('itemId');

		if (token && id && itemId) {
			getPageData();
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function getPageData() {
		try {
			const getOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			};
			const response = await fetch(`/collections/get?token=${token}&id=${id}`, getOptions);
			if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const collection = await response.json();

			const collectionItem = collection.items.find((item) => item.id === itemId);

			if (['MissedPickUp', 'PickUpRequested'].indexOf(collectionItem.status) < 0) {
				return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
			}

			const submissionResponse = await fetch(`/submissions/get?token=${token}&id=${collectionItem.submissionId}`, getOptions);
			if (submissionResponse.status === 503 && submissionResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const submission = await submissionResponse.json();

			const offerResponse = await fetch(`/offers/get?token=${token}&id=${collectionItem.offerId}`, getOptions);
			if (offerResponse.status === 503 && offerResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const offer = await offerResponse.json();

			setState((state) => ({
				...state,
				name: submission.customerInformation.name,
				images: submission.items[0].assets,
				brand: submission.items[0].brand.name,
				order: submission.externalReference,
				price: Number(offer.item.priceToCustomer.amount).toFixed(2),
			}));
			setIsLoading(false);

		} catch (error) {
			console.log(error);
			return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
		}
	}

	if(!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return(
		<>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-1050">
				<div className="row padding-x-15">
					<div className="col-lg-6 col-12 margin-bottom-30">
						<p>Hello {state.name},</p>
						<p>We&apos;re sorry, it looks like we had some issues with the pick-up of your bag.</p>
						<p>Don&apos;t worry, we can schedule another collection. Simply select a new pick-up time via the link below.</p>
						<Button
							to={`/PickUpRequest?token=${token}&id=${id}&itemId=${itemId}`}
							category="CTA Button Clicked"
							action="Reeschedule Pickup"
							label="Missed pickup - Reeschedule pickup"
							className="width-230 background-primary margin-bottom-15 btt-w-240 reschedule-btn">Reschedule the pick-up</Button>
						<p>
              If you have any further questions, please email us at <u><a href="mailto:reselfridges@selfridges.co.uk">
              reselfridges@selfridges.co.uk</a></u>.
						</p>
						<p>Once your pick-up has been rescheduled, you&apos;ll be one step closer to receiving your Selfridges credit.</p>
						<p>Thank you for using our service.</p>
						<p><strong>RESELFRIDGES</strong></p>
					</div>
					<div className="col-lg-6 col-12 margin-bottom-30">
						<DetailContainer
							images={state.images}
							brand={state.brand}
							price={state.price} />
					</div>
				</div>
			</div>
		</>
	);
}
