import React from 'react';
import './CtaPagesHeader.css';

export default function CtaPagesHeader(props) {

	return (
		<div className="pages-header">
			<h1 className="text-align-center my-0 padding-50">ORDER NO #{props.order}</h1>
			<div className="stepper-container">
				<div className="row no-gutters center mb-3">
					<div className="center-step">
						{
							props.paths.map((value, index) => (
								<div key={index} className="stepper">
									<div className="stepper-mark">
										<div className={`step-label ${index <= props.index ? 'color-black' : 'color-grey'}`}>{value}</div>
									</div>
								</div>
							))
						}
						
					</div>
				</div>
				<div className="row no-gutters">
					<div className="left-step">
						<div className="complete-line full"></div>
					</div>
					<div className="center-step">
						{
							props.paths.map((value, index) => (
								<div key={index} className="stepper">
									<div className="lines">
										<div className={`${index <= props.index ? 'complete-line' : 'incomplete-line'} half`}></div>
										<div className={`${index < props.index ? 'complete-line' : 'incomplete-line'} half`}></div>
									</div>
									<div className="stepper-mark">
										<div className={`${index <= props.index ? 'background-black' : 'background-grey'} oval-step align-self-center`}></div>
									</div>
								</div>
							))
						}
						
					</div>

					<div className="right-step">
						<div className={`${props.isRightCompleted ? 'complete-line' : 'incomplete-line'} full`}></div>
					</div>
				</div>
			</div>
		</div>
	);
}
