import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import Loading from '../../components/Loading/Loading';
import Button from '../../shared/components/UIElements/Button';

export default function PickUpRequestConfirmation() {

	const [state, setState] = useState({});
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const paths = ['Offer Requested', 'Offer Given', 'Pick-up Requested'];
	const pathIndex = 2;

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');
		const id = params.get('id');
		const itemId = params.get('itemId');

		if (token && id && itemId) {
			getPageData(id, itemId, token);
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function getPageData(id, itemId, token) {
		try {
			const options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			};
			const collectionResponse = await fetch(`/collections/get?token=${token}&id=${id}`, options);

			if (collectionResponse.status === 503 && collectionResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}

			const collection = await collectionResponse.json();

			const collectionItem = collection.items.find((item) => item.id === itemId);

			if (['PickUpRequested'].indexOf(collectionItem.status) < 0) {
				return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
			}

			const submissionResponse = await fetch(`/submissions/get?token=${token}&id=${collection.items[0].submissionId}`, options);

			if (submissionResponse.status === 503 && submissionResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}

			const submission = await submissionResponse.json();

			setState({
				order: submission.externalReference
			});
			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsQueryParamsValid(false);
		}
	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return (
		<>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-684">
				<h1>YOUR RESELFRIDGES COLLECTION HAS BEEN SCHEDULED!</h1>
				<p><strong>Great news: your bag collection is now scheduled.</strong></p>
				<p>
					What&apos;s next? We&apos;ll send you an email with everything
          you need to know to prepare your bag for collection using your own packaging.
				</p>
				<p>
					Have another bag you&apos;d like to sell through us? No problem. Simply upload
					it below and we&apos;ll be happy to take a look at it.
				</p>
				<Button
					to="/QuoteCreation"
					category="CTA Button Clicked"
					action="Sell another bag"
					label="Pickup Request Confirmation - Sell another bag"
					className="background-primary btt-w-240">Sell another bag</Button>
				<p>
					If you have any further question, please email us on <u><a href="mailto:reselfridges@selfridges.co.uk">
					reselfridges@selfridges.co.uk</a></u> quoting your reference number #{state.order}.
				</p>
				<p>
					Thanks again for using our service.
				</p>
				<p><strong>RESELFRIDGES</strong></p>
			</div>
		</>
	);
}
