import React, { useEffect, useState } from "react";
import ReactGa from "react-ga";
import { Redirect } from "react-router-dom";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import CalendarPicker from "../../components/CalendarPicker/CalendarPicker";
import CtaPagesHeader from "../../components/CtaPagesHeader/CtaPagesHeader";
import Input from "../../components/Input/Input";
import InputWithSuggestions from "../../components/InputWithSuggestions/InputWithSuggestions";
import Loading from "../../components/Loading/Loading";
import Select from "../../components/Select/Select";
import Button from "../../shared/components/UIElements/Button";
import { countryList, countryMapping } from "../../shared/util/mapping";
import { MINLENGTH, NONE, NUMBER, REQUIRE } from "../../shared/util/validator";
import "./PickUpRequest.css";

let id = "";
let itemId = "";
let token = "";

let mapsSearchTimeoutId = undefined;

export default function PickUpRequest() {
  const [state, setState] = useState({});
  const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
  const [redirectNextPage, setRedirectNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);

  const [pickUpDate, setPickupDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTermsVerified, setIsTermsVerified] = useState(false);

  const paths = ["Offer Requested", "Offer Given", "Schedule Pick-Up"];
  const pathIndex = 2;
  const countries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "United Kingdom",
  ];
  const timeSlots = [
    "09:00 - 15:00",
    "10:00 - 16:00",
    "11:00 - 17:00",
    "12:00 - 18:00",
  ];

  const submitFormEnabled =
    isTermsVerified &&
    selectedTimeSlot.length > 0 &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    countryName.length > 0 &&
    addressLine1.length > 0 &&
    city.length > 0 &&
    postCode.length > 0 &&
    phoneNumber.length > 0;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    token = params.get("token");
    id = params.get("id");
    itemId = params.get("itemId");

    if (token && id && itemId) {
      getPageData();
    } else {
      setIsQueryParamsValid(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && !isCaptchaLoaded) {
      loadCaptchaEnginge(6);
      setIsCaptchaLoaded(true);
    }
  }, [isLoading, isCaptchaLoaded]);

  async function getPageData() {
    try {
      const getOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const collectionResponse = await fetch(`/collections/get?token=${token}&id=${id}`, getOptions);

      if (collectionResponse.status === 503 && collectionResponse.json().then((data) => data.reason === "maintenance")) {
        window.location.href = '/Maintenance';
			}

      const collection = await collectionResponse.json();

      const collectionItem = collection.items.find(
        (item) => item.id === itemId
      );

      if (
        ["Created", "PickUpRequested", "MissedPickUp"].indexOf(
          collectionItem.status
        ) < 0
      ) {
        return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
      }

      const submissionResponse = await fetch(
        `/submissions/get?token=${token}&id=${collectionItem.submissionId}`,
        getOptions
      );

      if (submissionResponse.status === 503 && submissionResponse.json().then((data) => data.reason === "maintenance")) {
        window.location.href = '/Maintenance';
      }

      const submission = await submissionResponse.json();

      const countryName = countryList.find(item => item.code === submission.countryIsoCode).name
      if(submission.countryIsoCode && countryName) {
        setCountryName(countryName)
      }

      const offerResponse = await fetch(
        `/offers/get?token=${token}&id=${collectionItem.offerId}`,
        getOptions
      );

      if (offerResponse.status === 503 && offerResponse.json().then((data) => data.reason === "maintenance")) {
        window.location.href = '/Maintenance';
      }

      const offer = await offerResponse.json();

      const submissionItem = submission.items.find(
        (item) => item.id === itemId
      );

      setState({
        name: submission.customerInformation.name,
        image: submissionItem.assets[0] || "",
        brand: submissionItem.brand.name,
        order: collectionItem.submissionExternalId,
        offerPrice: Number(offer.item.priceToCustomer.amount).toFixed(2),
        numberOfPackages: 1,

        email: submission.customerInformation.email,
      });

      setIsLoading(false);
    } catch (error) {
      setIsQueryParamsValid(false);
    }
  }

  async function formSubmit() {
    const captchaElement = document.getElementById("captcha");
    const captchaValue = captchaElement.value;
    const isCaptchaValid = validateCaptcha(captchaValue);

    if (!isCaptchaValid) {
      captchaElement.value = "";
      captchaElement.focus();
      return;
    }

    if (isNaN(Number(phoneNumber))) {
      document.getElementById("phoneNumber").focus();
      return;
    }

    setIsLoading(true);
    try {
      pickUpDate.setUTCMinutes(0);
      pickUpDate.setUTCSeconds(0);
      pickUpDate.setUTCMilliseconds(0);
      const startSlot = pickUpDate.setUTCHours(selectedTimeSlot.slice(0, 2));
      const endSlot = pickUpDate.setUTCHours(selectedTimeSlot.slice(8, 10));

      const country = countryMapping(countryName);

      const data = {
        externalId: "",
        customerDetails: {
          name: `${firstName} ${lastName}`,
          email: state.email,
          address: {
            firstLine: addressLine1,
            secondLine: addressLine2,
            zipCode: postCode,
            city: city,
            country: country.code,
          },
          telephoneNumber: {
            countryCode: country.dialCode,
            number: phoneNumber,
          },
        },
        pickUpType: "Normal",
        slots: [
          {
            startDate: `${new Date(startSlot).toISOString()}`,
            endDate: `${new Date(endSlot).toISOString()}`,
          },
        ],
      };

      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        `/collections/schedule-pickup?token=${token}&id=${id}&itemId=${itemId}`,
        options
      );
      if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
        window.location.href = '/Maintenance';
      }

      if (response.status >= 400) {
        return setIsQueryParamsValid(false);
      }

      ReactGa.send({
        category: "CTA Button Clicked",
        action: "Send Images",
        label: "Additional Photos - Send Images",
      });

      setRedirectNextPage(true);
    } catch (error) {
      console.log(error);
      setIsQueryParamsValid(false);
    }
  }

  function onAddressLine1Change(addressLine1) {
    setAddressLine1(addressLine1);
    if (mapsSearchTimeoutId) {
      clearTimeout(mapsSearchTimeoutId);
      mapsSearchTimeoutId = undefined;
    }
    if (addressLine1 && addressLine1.length >= 3) {
      mapsSearchTimeoutId = setTimeout(() => {
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        const country = countryMapping(countryName);

        fetch(`/maps/get?query=${addressLine1}&countrySet=${country.code}`, options)
          .then((response) => {           
            if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
              window.location.href = '/Maintenance';
            }
            return response.json();
            })
          .then((response) => {
            setState((state) => ({
              ...state,
              mapsSuggestions: response,
              showSuggestions: true,
            }));
          });
      }, 500);
    } else {
      setState((state) => ({
        ...state,
        mapsSuggestions: [],
      }));
    }
  }

  function onAddressLine1Blur() {
    setState((state) => ({
      ...state,
      showSuggestions: false,
    }));
  }

  function onAddressLine1SuggestionAccepted(acceptedSuggestionAddress) {

    setCity(acceptedSuggestionAddress?.localName || '');
    setPostCode(acceptedSuggestionAddress?.extendedPostalCode || acceptedSuggestionAddress?.postalCode || '');
    setAddressLine1(acceptedSuggestionAddress?.streetName || '');

    setState((state) => ({
      ...state,
      mapsSuggestions: [],
      showSuggestions: false,
    }));
  }

  if (!isQueryParamsValid) {
    return <Redirect to="/" />;
  }

  if (redirectNextPage) {
    return (
      <Redirect
        to={`/PickUpRequestConfirmation?token=${token}&id=${id}&itemId=${itemId}`}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <CtaPagesHeader
        paths={paths}
        index={pathIndex}
        order={state.order}
        isRightCompleted={false}
      />

      <div className="center max-width-1050">
        <div className="row padding-x-15">
          <div className="col-12 col-md-8 mb-4">
            <p>Hello {state.name},</p>
            <p>
              We&apos;re delighted you&apos;ve accepted our quote of £
              {state.offerPrice} for your{" "}
              <span className="upper-case">{state.brand}</span> bag.
            </p>
            <p>
              Now, we just need to arrange for your bag to be collected, free of
              charge, at a time that suits you.
            </p>
            <p>
              Once your pick-up is scheduled, you&apos;ll be one step closer to
              receiving your Selfridges credit.
            </p>
            <p>
              If you have any further question, please email us on{" "}
              <u>
                <a href="mailto:reselfridges@selfridges.co.uk">
                  reselfridges@selfridges.co.uk
                </a>
              </u>{" "}
              quoting your reference number #{state.order}.
            </p>
            <p>Thanks for using our service.</p>

            <h1 className="schedule-pickup-titles cta-h1-titles">
              PACKAGING DETAILS
            </h1>
            <p>
              Find suitable packaging and we&apos;ll send you a pre-paid
              shipping label to print and attach to your parcel.
            </p>
            <h1 className="schedule-pickup-titles cta-h1-titles">
              SELECT YOUR PICK-UP DATE
            </h1>
            <div className="row no-gutters mb-4">
              <CalendarPicker startDays={1} setPickupDate={setPickupDate} />
            </div>

            <h1 className="schedule-pickup-titles cta-h1-titles">
              SELECT PICK-UP TIME
            </h1>
            <div className="row no-gutters mb-4">
              {timeSlots.map((slot, index) => (
                <div
                  key={index}
                  className={`col set-time ${
                    selectedTimeSlot === slot ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTimeSlot(slot)}
                >
                  {selectedTimeSlot === slot ? (
                    <div className="check-black-container ml-0 oval-primary background-primary">
                      <div className="check-black"></div>
                    </div>
                  ) : (
                    <div className="background-secondary oval-secondary"></div>
                  )}
                  <p>{slot}</p>
                </div>
              ))}
            </div>

            <div className="row no-gutters align-items-baseline">
              <h1 className="schedule-pickup-titles cta-h1-titles mr-4">
                ADD PICK-UP ADDRESS
              </h1>
              <p>Required fields*</p>
            </div>
            <div className="row">
              <div className="col-md-6 padding-b-24">
                <Input
                  id="firstName"
                  type="text"
                  label="First Name"
                  validators={[REQUIRE()]}
                  setter={setFirstName}
                  field={firstName}
                  placeholder="First Name"
                  errorText="First name is required."
                  required={true}
                />
              </div>

              <div className="col-md-6 padding-b-24">
                <Input
                  id="lastName"
                  type="text"
                  label="Last Name"
                  validators={[REQUIRE()]}
                  setter={setLastName}
                  field={lastName}
                  placeholder="Last Name"
                  errorText="Last Name is required."
                  required={true}
                />
              </div>

              <div className="col-md-12 padding-b-24">
                <Select
                  id="country"
                  label="Country"
                  disabled
                  validators={[REQUIRE()]}
                  setter={setCountryName}
                  field={countryName}
                  placeholder="Select your country"
                  errorText="Please pick a country"
                  options={countries}
                  required={true}
                />
              </div>

              <div className="col-md-12 padding-b-24">
                <InputWithSuggestions
                  id="addressLine1"
                  type="text"
                  label="Address"
                  validators={[REQUIRE()]}
                  setter={onAddressLine1Change}
                  field={addressLine1}
                  onSuggestionAccepted={onAddressLine1SuggestionAccepted}
                  suggestions={state.mapsSuggestions}
                  showSuggestions={state.showSuggestions}
                  onBlur={onAddressLine1Blur}
                  placeholder="Address Line 1"
                  errorText="Address Line 1 is required."
                  required={true}
                />

                <Input
                  id="addressLine2"
                  type="text"
                  label=""
                  validators={[]}
                  setter={setAddressLine2}
                  field={addressLine2}
                  placeholder="Address Line 2"
                  errorText=""
                  required={false}
                />
              </div>

              <div className="col-md-12 padding-b-24">
                <Input
                  id="city"
                  type="text"
                  label="City"
                  validators={[REQUIRE()]}
                  setter={setCity}
                  field={city}
                  placeholder="City"
                  errorText="City is required."
                  required={true}
                />
              </div>

              <div className="col-md-12 padding-b-24">
                <Input
                  id="postcode"
                  type="text"
                  label="Postcode"
                  validators={[REQUIRE()]}
                  setter={setPostCode}
                  field={postCode}
                  placeholder="Postcode"
                  errorText="Postcode is required."
                  required={true}
                />
              </div>

              <div className="col-md-12 padding-b-24">
                <Input
                  id="phoneNumber"
                  type="text"
                  label="Phone Number"
                  validators={[REQUIRE(), MINLENGTH(6), NUMBER()]}
                  setter={setPhoneNumber}
                  field={phoneNumber}
                  placeholder="Phone Number"
                  errorText="Phone Number is required."
                  required={true}
                />
                <p className="comment-text">
                  Don&apos;t worry, we&apos;ll only reach out if we need to
                  contact you about your collection.
                </p>
              </div>
            </div>

            <div className="margin-bottom-30">
              <LoadCanvasTemplateNoReload />

              <Input
                element="input"
                type="text"
                label=""
                id="captcha"
                placeholder="Insert the characters you see in the image above"
                validators={[NONE()]}
              />
            </div>

            <div className="form-control d-flex">
              <input
                id="terms-conditions"
                type="checkbox"
                onChange={() => setIsTermsVerified(!isTermsVerified)}
                checked={isTermsVerified}
                value={isTermsVerified}
                className="padding-r-24 normal-checkbox"
              />
              <label htmlFor="terms-conditions" className="normal-label">
                <div>
                  By completing this form and ticking this box, you understand
                  your information will be shared with our distribution partner,
                  and you have read and understood our{" "}
                  <a href="/TermsConditions" target="_blank">
                    <b>
                      <u>Terms & Conditions</u>
                    </b>
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/"
                  >
                    <b>
                      <u>Privacy & Cookie Policy</u>
                    </b>
                  </a>
                  .
                </div>
              </label>
            </div>

            <p className="comment-text">
              Before you submit, please make sure all your details are correct
            </p>
            <Button
              onClick={formSubmit}
              className="background-primary btt-w-240"
              disabled={!submitFormEnabled}
            >
              Submit
            </Button>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <div className="summary-box">
              <h1 className="schedule-pickup-titles upper-case cta-h1-titles">
                Summary
              </h1>
              <div className="row">
                <div className="col-5">
                  {<img src={state.image} alt="" className="summary-image" />}
                </div>
                <div className="col-7">
                  <div className="summary-info">
                    <h5 className="summary-info-title">Brand</h5>
                    <p className="summary-info-desc upper-case">
                      {state.brand}
                    </p>
                  </div>
                  <div className="summary-info">
                    <h5 className="summary-info-title">Offer price</h5>
                    <p className="summary-info-desc">
                      £{parseFloat(state.offerPrice).toFixed(2)}
                    </p>
                  </div>
                  <div className="summary-info">
                    <h5 className="summary-info-title">Number of packages:</h5>
                    <p className="summary-info-desc">
                      {state.numberOfPackages}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
