import React, { useEffect, useState } from 'react';

export default function CalendarPicker(props) {

	const { startDays, setPickupDate } = props;
	const numOfDays = 14;
	const disabledDays = ['Sat', 'Sun'];
	const [calendarDates, setCalendarDates] = useState([]);
	const [pickedDate, setPickedDate] = useState();

	useEffect(() => {
		const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		// Check if starts saturday or sunday and change to monday
		const startDate = new Date();
		startDate.setDate(startDate.getDate() + startDays);

		// If calculated Day is Sunday or Saturday
		const startWeekDay = startDate.getDay();
		if (startWeekDay === 0 || startWeekDay === 6) {
			startDate.setDate(startDate.getDate() + (startWeekDay === 0 ? 1 : 2));
		}

		var newCalendar = [];

		const computedDate = new Date(startDate);

		let i = 0;
		do {
			newCalendar.push({
				weekDay: daysOfWeek[computedDate.getDay()],
				monthDay: computedDate.getDate(),
				date: new Date(computedDate)
			});

			computedDate.setDate(computedDate.getDate() + 1);
			i++;
		} while (i < numOfDays);

		setPickedDate(startDate.getDate());
		setCalendarDates(newCalendar);
		setPickupDate(startDate);

	}, [startDays]);

	function getCalendarClassName(day) {
		return `${day.monthDay === pickedDate && 'dark-selection'} ${disabledDays.includes(day.weekDay) ? 'date-picker-disabled' : 'calendar-box--selectable'}`;
	}

	function handleDatePicker(date) {
		if(!disabledDays.includes(date.weekDay)) {
			setPickedDate(date.monthDay);
			setPickupDate(date.date);
		}
	}

	return (
		calendarDates.length === 0
			? <></>
			: calendarDates.map((day, index) =>
				<div key={index}
					style={{
						borderTopWidth: index < 7 && '2px',
						borderBottomWidth: index > 6 && '2px',
						borderLeftWidth: (index === 0 || index === 7) && '2px',
						borderRightWidth: (index === 6 || index === 13) && '2px',
					}}
					className={`calendar-box ${getCalendarClassName(day)}`}
					onClick={() => handleDatePicker(day) }>
					<p>{day.weekDay}</p>
					<p><strong>{day.monthDay}</strong></p>
				</div>
			)
	);
}
