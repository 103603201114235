import React from 'react';
import './DetailContainer.css';

export default function DetailContainer(props) {
	return (
		<>
			<h2 className="details__title">Here&apos;s what you sent</h2>
			<div className="details__container">
				<div className="details__container__title">YOUR IMAGES</div>
				<div className="details__container__images">
					{props.images.map((image, index) => <img key={index} src={image} alt="" className="col-6 col-lg-4" />)}
				</div>
				<div className="details__container__title">BRAND NAME</div>
				<div className="details__container__text">{props.brand.toUpperCase()}</div>
				{
					props.price && (
						<>
							<div className="details__container__title">PRICE</div>
							<div className="details__container__text">£{parseInt(props.price).toFixed(2)}</div>
						</>)
				}
			</div>
		</>
	);
}
