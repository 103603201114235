import { createBrowserHistory } from "history";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ReactGa from "react-ga";
import ReactGA4 from "react-ga4";
import TagManager from 'react-gtm-module';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Landing from "./landing/pages/Landing";
import FAQs from "./others/FAQs";
import Maintenance from "./others/Maintenance";
import TermsAndConditions from "./others/TermsAndConditons";
import AdditionalPhotos from "./pages/AdditionalPhotos/AdditionalPhotos";
import AdditionalPhotosConfirmation from "./pages/AdditionalPhotosConfirmation/AdditionalPhotosConfirmation";
import MissedPickUp from "./pages/MissedPickUp/MissedPickUp";
import OfferAccepted from "./pages/OfferAccepted/OfferAccepted";
import OfferDeclined from "./pages/OfferDeclined/OfferDeclined";
import PickUpRequest from "./pages/PickUpRequest/PickUpRequest";
import PickUpRequestConfirmation from "./pages/PickUpRequestConfirmation/PickUpRequestConfirmation";
import QuoteExpired from "./pages/QuoteExpired/QuoteExpired";
import Confirmation from "./quotes/pages/Confirmation";
import QuoteCreation1 from "./quotes/pages/QuoteCreation1";
import QuoteCreation2 from "./quotes/pages/QuoteCreation2";
import QuoteCreation3 from "./quotes/pages/QuoteCreation3";
import "./shared/components/bootstrap-grid.css";
import MainFooter from "./shared/components/Navigation/MainFooter";
import MainHeader from "./shared/components/Navigation/MainHeader";
import TopScroller from "./shared/components/TopScroller";
import { FormContext } from "./shared/context/form-context";

const App = () => {
  var history = createBrowserHistory();

  const ACCEPT_GA_COOKIES_NAME = useMemo(() => "acceptGACookie", []);

  const isGACookiesAlreadyAccepted =
    getCookieConsentValue(ACCEPT_GA_COOKIES_NAME) === "true";

  const [isGACookiesAccepted, setGACookiesAccepted] = useState(
    isGACookiesAlreadyAccepted
  );

  const [cookies, , removeCookie] = useCookies();

  /* eslint-disable no-undef */
  useEffect(() => {
    if (isGACookiesAccepted) {
      if (process.env.REACT_APP_ANALYTICS_KEY) {
        ReactGa.initialize(process.env.REACT_APP_ANALYTICS_KEY);
      }
      if (process.env.REACT_APP_ANALYTICS_KEY4) {
        ReactGA4.initialize(process.env.REACT_APP_ANALYTICS_KEY4);
      }
      if (process.env.REACT_APP_ANALYTICS_GTM_KEY4) {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_ANALYTICS_GTM_KEY4
        }
        TagManager.initialize(tagManagerArgs);
      }
    } else {
      Object.entries(cookies).forEach(([key]) => {
        if (key !== ACCEPT_GA_COOKIES_NAME) {
          removeCookie(key);
        }
      });
    }
  }, [isGACookiesAccepted]);

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_KEY && isGACookiesAccepted) {
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
    if (process.env.REACT_APP_ANALYTICS_KEY4 && isGACookiesAccepted) {
      ReactGA4.send('pageview');
    }
  }, [history]);
  /* eslint-enable no-undef */

  const [quote1, setQuote1] = useState({});
  const [quote2, setQuote2] = useState({});
  const [quoteImages, setQuoteImages] = useState({});

  const [submissionID, setSubmissionID] = useState("");

  const quoteCreation1Handler = useCallback((object) => {
    setQuote1(object);
  }, []);

  const quoteCreation2Handler = useCallback((object) => {
    setQuote2(object);
  }, []);

  const quoteImagesHandler = useCallback((object) => {
    setQuoteImages(object);
  }, []);

  const submissionIDHandler = useCallback((string) => {
    setSubmissionID(string);
  }, []);

  const clean = useCallback(() => {
    setQuote1({});
    setQuote2({});
    setQuoteImages({});
  }, []);

  const checker = (object, link) => {
    if (Object.keys(object).length === 0) {
      return <Redirect to={link} />;
    }
  };

  const checkerSubmission = (string, link) => {
    if (string === "") {
      return <Redirect to={link} />;
    }
  };

  return (
    <FormContext.Provider
      value={{
        quote1: quote1,
        quote2: quote2,
        quoteImages: quoteImages,
        submissionID: submissionID,
        quoteCreation1Handler: quoteCreation1Handler,
        quoteCreation2Handler: quoteCreation2Handler,
        quoteImagesHandler: quoteImagesHandler,
        submissionIDHandler: submissionIDHandler,
        clean: clean,
      }}
    >
      <BrowserRouter history={history}>
        <TopScroller />
        <MainHeader />
        <main>
        {/* eslint-disable no-undef */ 
          process.env.REACT_APP_SITE_MODE === "MAINTENANCE" ? 
            <Switch>
              <Route path="/Maintenance" exact>
                <Maintenance />
              </Route>

              <Route path="/AdditionalPhotos" exact>
                <AdditionalPhotos />
              </Route>

              <Route path="/AdditionalPhotosConfirmation" exact>
                <AdditionalPhotosConfirmation />
              </Route>

              <Route path="/MissedPickUp" exact>
                <MissedPickUp />
              </Route>

              <Route path="/OfferAccepted" exact>
                <OfferAccepted />
              </Route>

              <Route path="/OfferDeclined" exact>
                <OfferDeclined />
              </Route>

              <Route path="/PickUpRequest" exact>
                <PickUpRequest />
              </Route>

              <Route path="/PickUpRequestConfirmation" exact>
                <PickUpRequestConfirmation />
              </Route>

              <Route path="/QuoteExpired" exact>
                <QuoteExpired />
              </Route>

              <Redirect to="/Maintenance" />
            </Switch>
          :
            <Switch>
              <Route path="/" exact>
                <Landing />
              </Route>
              <Route path="/TermsConditions" exact>
                <TermsAndConditions />
              </Route>
              <Route path="/FAQs" exact>
                <FAQs />
              </Route>
              <Route path="/QuoteCreation" exact>
                <QuoteCreation1 />
              </Route>
              <Route path="/QuoteCreation2" exact>
                {checker(quote1, "/QuoteCreation")}
                <QuoteCreation2 />
              </Route>
              <Route path="/QuoteCreation3" exact>
                {checker(quote1, "/QuoteCreation")}
                <QuoteCreation3 />
              </Route>

              <Route path="/Confirmation" exact>
                {checkerSubmission(submissionID, "/QuoteCreation")}
                <Confirmation />
              </Route>

              <Route path="/AdditionalPhotos" exact>
                <AdditionalPhotos />
              </Route>

              <Route path="/AdditionalPhotosConfirmation" exact>
                <AdditionalPhotosConfirmation />
              </Route>

              <Route path="/MissedPickUp" exact>
                <MissedPickUp />
              </Route>

              <Route path="/OfferAccepted" exact>
                <OfferAccepted />
              </Route>

              <Route path="/OfferDeclined" exact>
                <OfferDeclined />
              </Route>

              <Route path="/PickUpRequest" exact>
                <PickUpRequest />
              </Route>

              <Route path="/PickUpRequestConfirmation" exact>
                <PickUpRequestConfirmation />
              </Route>

              <Route path="/QuoteExpired" exact>
                <QuoteExpired />
              </Route>

              <Redirect to="/" />
            </Switch>
        /* eslint-enable no-undef */}
        </main>

        {/* eslint-disable no-undef */ 
          process.env.REACT_APP_SITE_MODE !== "MAINTENANCE" && 
          <Fragment>
            <MainFooter /> 
            <CookieConsent
              enableDeclineButton
              declineButtonStyle={{
                background: "transparent",
                color: "#DA1E28",
                fontFamily: "avalon-bold",
              }}
              buttonStyle={{
                background: "transparent",
                color: "rgb(255, 212, 45)",
                fontFamily: "avalon-bold",
              }}
              onAccept={() => setGACookiesAccepted(true)}
              cookieName={ACCEPT_GA_COOKIES_NAME}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </Fragment>
        /* eslint-enable no-undef */}

      </BrowserRouter>
    </FormContext.Provider>
  );
};

export default App;
