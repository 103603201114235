import React from 'react';
import loadingSVG from '../../assets/images/ic-loading.svg';

export default function Loading() {
	return (
		<div className="text-align-center">
			<br/>
			<br/>
			<img
				src={loadingSVG}
				width="30px"
				height="30px"
				alt="loading"
				className="text-align-center spin"
			/>
		</div>
	);
}
