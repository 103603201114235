import React, { useState } from "react";
import { validate } from "../../shared/util/validator";
import styles from "./InputWithSuggestions.module.css";

// This component was built to be used with results from this API
// https://docs.microsoft.com/en-us/rest/api/maps/search/get-search-address
export default function InputWithSuggestions(props) {
  const {
    id,
    type,
    label,
    validators,
    setter,
    field,
    placeholder,
    errorText,
    required,
    showSuggestions,
    suggestions,
    onSuggestionAccepted,
    onBlur,
  } = props;
  const [isValid, setIsValid] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  function validateField(event) {
    setIsValid(validate(field, validators));
    setHasChanged(true);
    const listElement = document.getElementById("suggestion-list");
    const didClickMenu = listElement.contains(event.relatedTarget);
    if (!didClickMenu) {
      onBlur();
    }
  }

  return (
    <div
      className={`form-control ${
        hasChanged && !isValid && "form-control--invalid"
      }`}
    >
      <label htmlFor={id}>
        {label}
        {required && " *"}
      </label>
      <div className={styles.autocompleteContainer}>
        <input
          name="to-search-address"
          autoComplete="to-search-address"
          id={id}
          type={type}
          onChange={(event) => setter(event.target.value)}
          onBlur={validateField}
          value={field}
          placeholder={`${placeholder}${required ? " *" : ""}`}
        />
        {hasChanged && !isValid ? <p>{errorText}</p> : <></>}
        {showSuggestions && (
          <ul id="suggestion-list" className={styles.autocompleteList}>
            {!suggestions || suggestions.length <= 0 ? (
              <li className={styles.autocompleteItemEmpty}>No suggestions</li>
            ) : (
              suggestions.map((suggestion, index) => (
                <li
                  key={`autocomplete-item-${index}`}
                  tabIndex="-1"
                  className={styles.autocompleteItem}
                  onClick={() => onSuggestionAccepted(suggestion.address)}
                >
                  {suggestion.address.freeformAddress},{" "}
                  {suggestion.address.country}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
