import React, { useEffect } from 'react';
import warning from '../assets/images/warning.svg';
import Container from '../shared/components/UIElements/Container';
import './maintenance.css';

const Maintenance = () => {


	useEffect(() => {
		window.location.href = 'https://www.selfridges.com/GB/en/reselfridges/resale';
	}, [])

	return (
		<Container>
			{/* <div className="maintenance-wrapper">
				<img className="warning-image mb-32" src={warning} alt="warning sign" />
				<h1 className="maintenance-title mb-32">Our Site is Under Maintenance</h1>
				<p className="maintenance-description mb-48">
					Our website is currently undergoing scheduled maintenance.
					We should be back shortly. Thank you for your patience.
				</p>
				<a className="redirect-button button background-primary" type="button" href="https://www.selfridges.com/">
					Take me to selfridges.com
				</a>
			</div> */}
		</Container>
	);
};

export default Maintenance;