import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import DetailContainer from '../../components/DetailContainer/DetailContainer';
import Loading from '../../components/Loading/Loading';
import Button from '../../shared/components/UIElements/Button';
import './QuoteExpired.css';

export default function QuoteExpired() {

	const [state, setState] = useState({});
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const paths = ['Offer Requested', 'Offer Given', 'Quote Expired'];
	const pathIndex = 2;

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');
		const id = params.get('id');

		if (token && id) {
			getPageData(id, token);
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	async function getPageData(id, token) {
		try {
			const options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			};
			const offerResponse = await fetch(`/offers/get?token=${token}&id=${id}`, options);

			if (offerResponse.status === 503 && offerResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}

			const offer = await offerResponse.json();

			if (offer.status !== 'Timedout') {
				return setIsQueryParamsValid(false);
			}

			const submissionResponse = await fetch(`/submissions/get?token=${token}&id=${offer.item.submissionId}`, options);

			if (submissionResponse.status === 503 && submissionResponse.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}

			const submission = await submissionResponse.json();

			setState({
				name: submission.customerInformation.name,
				images: submission.items[0].assets,
				brand: submission.items[0].brand.name,
				order: submission.externalReference
			});
			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsQueryParamsValid(false);
		}

		setIsLoading(false);
	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (isLoading) {
		return <Loading/>;
	}

	return (
		<>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-1050">
				<div className="row padding-x-15">
					<div className="col-lg-6 col-12 margin-bottom-30">
						<p>Hello {state.name},</p>
						<p>Unfortunately, the quote we gave you for your {state.brand.toUpperCase()} bag has now expired.</p>
						<p>
            Still interested in selling your bag through RESELFRIDGES? No problem. Simply check below
						to resubmit your bag for a new quote.
						</p>
						<Button
							to="/QuoteCreation"
							category="CTA Button Clicked"
							action="Sell your bag"
							label="Quote expired - Sell your bag"
							className="background-primary btt-w-240">Sell your bag</Button>
						<p>
            If you have any further question, please email us on <u><a href = "reselfridges@selfridges.co.uk">
            reselfridges@selfridges.co.uk</a></u> quoting your reference number #{state.order}.
						</p>
						<p>Thanks for using our service.</p>
						<p><strong>RESELFRIDGES</strong></p>
					</div>
					<div className="col-lg-6 col-12 margin-bottom-30">
						<DetailContainer
							images={state.images}
							brand={state.brand} />
					</div>
				</div>
			</div>
		</>
	);
}
