import React from 'react';

import './Backdrop.css';

const Backdrop = props => {
	return(
		<div id="backdrop-hook">
			<div className="backdrop" onClick={props.onClick}></div>
		</div>
	);
};

export default Backdrop;
