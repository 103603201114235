import React from 'react';
import './TickerTape.css';

const TickerTape = () => {
	return (
		<React.Fragment>
			<section className="scroll text1">
				<div>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges 
					<span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges 
					<span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
				</div>

				<div>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
           Reselfridges  <span>  Reselfridges  </span> Reselfridges 
					<span>  Reselfridges  </span> Reselfridges  <span>  Reselfridges  </span>
				</div>
			</section>
		</React.Fragment>
	);
};

export default TickerTape;
