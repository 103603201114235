import { createContext } from 'react';

export const FormContext = createContext({
	quote1: {},
	quote2: {},
	quoteImages: {},

	submissionID: '',
	clean: () => {

	},
	quoteCreation1Handler: () => {

	},
	quoteCreation2Handler: () => {

	},
	quoteImagesHandler: () => {

	},
	submissionIDHandler: () => {

	}
});
