import React, { useEffect, useRef, useState } from 'react';
import ReactGa from 'react-ga';
import { Link, Redirect } from 'react-router-dom';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { ReactComponent as CloseIcon } from '../../assets/images/ic-close.svg';
import CtaPagesHeader from '../../components/CtaPagesHeader/CtaPagesHeader';
import DetailContainer from '../../components/DetailContainer/DetailContainer';
import Loading from '../../components/Loading/Loading';
import ModalGuideline from '../../quotes/components/ModalGuideline';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Button';
import { NONE } from '../../shared/util/validator';
import './AdditionalPhotos.css';

const submittedImages = {};
let token = '';
let id = '';
let itemId = '';
let scrollPosition = 0;

export default function AdditionalPhotos() {

	const paths = ['Offer Requested', 'More Images Requested', 'Offer Given'];
	const pathIndex = 1;

	const [state, setState] = useState({
		step: 1,
		submittedImages: [],
	});
	const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);
	const [redirectNextPage, setRedirectNextPage] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [showGuidelines, setShowGuidelines] = useState(false);

	const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);

	const [isValidSize, setIsValidSize] = useState(true);

	const fileInput = useRef();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		token = params.get('token');
		id = params.get('id');
		itemId = params.get('itemid');

		if (token && id && itemId) {
			getPageData();
		} else {
			setIsQueryParamsValid(false);
		}
	}, []);

	useEffect(() => {
		if (!isLoading && !isCaptchaLoaded && state.step === 2) {
			loadCaptchaEnginge(6);
			setIsCaptchaLoaded(true);
		}
	}, [isLoading, isCaptchaLoaded, state.step]);

	async function getPageData() {
		const options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		};
		try {
			const response = await fetch(`/submissions/get?token=${token}&id=${id}`, options);
			if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
				window.location.href = '/Maintenance';
			}
			const responseData = await response.json();

			const item = responseData.items.find((item) => item.id === itemId);

			if (item.status !== 'MoreInformation') {
				return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
			}

			setState((state) => ({
				...state,
				name: responseData.customerInformation.name,
				images: item.assets,
				brand: item.brand.name,
				order: responseData.externalReference,
				details: item.additionalPhotos
			}));
			setIsLoading(false);

		} catch (error) {
			console.log(error);
			return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
		}

	}

	function onReviewSubmissionGuidelinesClick(event) {
		event.preventDefault();
		scrollPosition = window.pageYOffset;
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', 'overflow: hidden; position: fixed; width: 100%; left: 0; top: 0;');

		ReactGa.event({
			category: 'View Photo Guidelines',
			action: 'Opens Modal Guidelines'
		});
		setShowGuidelines(true);
	}

	function closeSubmissionGuidelines(event) {
		event.preventDefault();
		window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
		document.getElementById('root').setAttribute('style', '');
		setShowGuidelines(false);
	}

	function onAddMoreImagesClick() {
		ReactGa.send({
			category: 'CTA Button Clicked',
			action: 'Next Page',
			label: 'Additional Photos - Add more Images'
		});
		setState((state) => ({
			...state,
			step: 2,
		}));
		setTimeout(() => {
			document.getElementById('step2').scrollIntoView({ behavior: 'smooth' });
		}, 0);
	}

	function toBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	function onInputFileChange(event) {
		event.persist();
		setIsValidSize(true);

		if (event.target.files.length > 0) {
			setState((state) => {
				const newState = {
					...state,
					submittedImages: state.submittedImages.concat(
						[...event.target.files]
						.filter((file) => {
							const acceptedTypes = ['image/png', 'image/jpeg'];
							const isValidType = acceptedTypes.includes(file.type);

							if (!isValidType) {
								console.log(`Invalid image type: ${file.type}\nWe only accept jpeg and png images.`);
							}
													
							if (file.size > 5000000) {
								setIsValidSize(false);
							}
							return file.size <= 5000000 && isValidType;
						})
						.map((file, index) => {						
							const url = URL.createObjectURL(file);

							setTimeout(async () => {
								const f = await toBase64(file);
								submittedImages[url] = f;
							}, 0);

							return {
								url,
								filename: `${Date.now()}-${index}`,
								type: file.type
							};
						})
					)
				};

				setTimeout(() => {
					event.target.value = '';
				}, 0);
				
				return newState;
			});
		}
	}

	function deletePhoto(index) {
		let isDeleted = false;
		setState((state) => {
			if (!isDeleted) {
				const urls = state.submittedImages.splice(index, 1);
				delete submittedImages[urls[0]];
				isDeleted = true;
			}
			return {
				...state
			};
		});
	}

	function onUploadImageClick() {
		fileInput.current.click();
		ReactGa.send({
			category: 'CTA Button Clicked',
			action: 'Upload Image',
			label: 'Additional Photos - Upload Images'
		});
	}

	async function submitInfo() {
		setIsValidSize(true);

		if (state.submittedImages.length === 0) {
			return;
		}

		const captchaElement = document.getElementById('captcha');
		const captchaValue = captchaElement.value;
		const isCaptchaValid = validateCaptcha(captchaValue);

		if (!isCaptchaValid) {
			captchaElement.value = '';
			captchaElement.focus();
			return;
		}

		setIsLoading(true);

		Promise.all(state.submittedImages.map((imageInfo) => {
			return new Promise(async (resolve, reject) => {
				const photoOptions = {
					'method': 'POST',
					'headers': {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						filename: `${imageInfo.filename}.${imageInfo.type.split('/')[1]}`,
						ImageData: submittedImages[imageInfo.url],
						Type: imageInfo.type,
					})
				};
				try {
					const photosResponse = await fetch('/submissions/photos/create', photoOptions);
					if (photosResponse.status === 503 && photosResponse.json().then((data) => data.reason === "maintenance")) {
						window.location.href = '/Maintenance';
					}

					if (photosResponse.status >= 400) {
						setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
					}

					const photosResponseData = await photosResponse.json();

					if (photosResponseData.link) {
						resolve(photosResponseData.link);
					}
				} catch (err) {
					reject(err);
					setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
				}
			});
		})).then(async (assets) => {
			const createAssetsOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					assets
				})
			};

			try {
				const url = `/submissions/assets/create?id=${id}&itemId=${itemId}&token=${token}`;
				const createAssetsResponse = await fetch(url, createAssetsOptions);
				if (createAssetsResponse.status === 503 && createAssetsResponse.json().then((data) => data.reason === "maintenance")) {
					window.location.href = '/Maintenance';
				}

				if (createAssetsResponse.status >= 400) {
					return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
				}
				ReactGa.send({
					category: 'CTA Button Clicked',
					action: 'Send Images',
					label: 'Additional Photos - Send Images'
				});
				setRedirectNextPage(true);
			} catch (err) {
				console.log(err);
				return setIsQueryParamsValid(false); // request is invalid, so, let's "hammer" this in order to redirect to "/"
			}
		});

	}

	if (!isQueryParamsValid) {
		return <Redirect to="/" />;
	}

	if (redirectNextPage) {
		return <Redirect to={`/AdditionalPhotosConfirmation?token=${token}&id=${id}&itemId=${itemId}`} />;
	}

	if (isLoading) {
		return <Loading />;
	}

	const step2 = (
		<div id="step2" className="additional-photos__step2-container">
			<div className="row padding-x-15 justify-content-center">
				<div className="col-12 col-lg-10 text-align-center">
					<p><strong>TIME TO ATTACH YOUR PHOTOS</strong></p>

					<p>Please upload all photos marked with an asterisk, as well as any extras you think might help our valuation experts to assess
          your bag. Remember: the more images you provide, the more accurate your quote will be. For more information regarding our RESELFRIDGES
          service, please read our <Link to="/FAQs"><u>FAQs</u></Link>, <Link to="/TermsConditions"><u>Terms & Conditions</u></Link> and <a href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/"><u>Privacy Policy</u></a>.</p>

					<Button
						className="margin-bottom-30 background-secondary btt-w-240"
						onClick={onReviewSubmissionGuidelinesClick}>
            Review our submission guidelines
					</Button>

					{!isValidSize && <p className="margin-bottom-30 error">Error uploading image. Must be smaller than 5MB</p>}
					<div className="margin-bottom-30">
						<input
							ref={fileInput} 
							accept="image/x-png,image/gif,image/jpeg"
							multiple={true} 
							type="file" 
							style={{ display: 'none' }} 
							onChange={onInputFileChange} 
						/>
						<div className="row justify-content-center">
							{
								state.submittedImages.length === 0
									? <p>No images uploaded yet...</p>
									: state.submittedImages.map((image, index) => (
										<div key={index} className="additional-photos__photo-container col-6 col-lg-4">
											<CloseIcon className="additional-photos__photo-container__close" onClick={() => deletePhoto(index)}></CloseIcon>
											<img className="additional-photos__photo" alt="" src={image.url} />
										</div>
									))
							}
						</div>
					</div>
					<Button className="margin-bottom-30 background-primary btt-w-240" onClick={() => onUploadImageClick()}>Upload image</Button>

					<p className="margin-bottom-30"><strong>IMPORTANT:</strong> If there is any damage please let us know and provide a photo of the damage - without this we
          will not be able to provide a quote.</p>

					<div className="margin-bottom-30">
						<LoadCanvasTemplateNoReload />

						<Input element="input" type="text" label=""
							id="captcha"
							placeholder="Insert the characters you see in the image above"
							validators={
								[NONE()]
							} />
					</div>

					<Button
						className="margin-bottom-30 background-primary btt-w-240"
						disabled={state.submittedImages.length === 0}
						onClick={() => submitInfo()}>Send your images</Button>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<ModalGuideline
				show={showGuidelines}
				onCancel={closeSubmissionGuidelines}
			/>
			<CtaPagesHeader paths={paths} index={pathIndex} order={state.order} isRightCompleted={false} />

			<div className="center max-width-1050">
				<div className="margin-bottom-30 row padding-x-15">
					<div className="col-lg-6 col-12 margin-bottom-60">
						<p>Hello {state.name},</p>
						<p>Thanks again for sharing your bag via <strong>RESELFRIDGES</strong>.</p>
						<p>
                Our valuation experts have asked to see a few more images of your bag,
                to help them give you an accurate quote.
						</p>
						<p>
							<strong>They&apos;re keen to see:</strong>
						</p>
						<span>
							<ul className="additional-photos__list">
								{state.details.map((detail, index) => <li key={index}>- {detail}</li>)}
							</ul>
						</span>
						<p>
                Once the team has received them, we&apos;ll let you know if your bag has
                been approved and send you a quote within two working days.
						</p>
						<p>Thanks for using our RESELFRIDGES service.</p>
						<p><strong>RESELFRIDGES</strong></p>
						<Button className="background-primary btt-w-240" onClick={() => onAddMoreImagesClick()}>Add more images</Button>
					</div>
					<div className="col-lg-6 col-12 margin-bottom-30">
						<DetailContainer
							images={state.images}
							brand={state.brand} />
					</div>
				</div>
				{state.step === 2 && step2}
			</div>
		</>
	);
}
