import React, { useContext, useEffect, useState } from "react";
import ReactGa from "react-ga";
import { useHistory } from "react-router-dom";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import loadingSVG from "../../assets/images/ic-loading.svg";
import Checkbox from "../../shared/components/FormElements/Checkbox";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/UIElements/Button";
import Container from "../../shared/components/UIElements/Container";
import ContainerFluid from "../../shared/components/UIElements/ContainerFluid";
import Modal from "../../shared/components/UIElements/Modal";
import { FormContext } from "../../shared/context/form-context";
import { useForm } from "../../shared/hooks/form-hook";
import { formInitial1, formInitial2 } from "../../shared/util/formVariables";
import {
  brandMapping,
  conditionsMapping,
  countryMapping,
  sizesMapping,
} from "../../shared/util/mapping";
import { MAXLENGTH, NONE } from "../../shared/util/validator";
import QuoteCreationHeader from "../components/QuoteCreationHeader";
import "./QuoteCreation.css";

const QuoteCreation3 = () => {
  const form = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, showError] = useState(false);
  const [items, setItems] = useState([]);
  const [formState, inputHandler] = useForm(
    {
      additionalInformation: {
        value: "",
        isValid: true,
      },
      termsCondition: {
        value: false,
        isValid: true,
      },
    },
    false
  );

  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);

  const [personal, setPersonal] = useState(formInitial1());
  const [bag, setBag] = useState(formInitial2());
  const [images, setImages] = useState();

  const identifiedForm = form.quote1;
  useEffect(() => {
    if (Object.keys(identifiedForm).length !== 0) {
      setPersonal(identifiedForm);
    }
  }, [setPersonal, identifiedForm]);

  const identifiedForm2 = form.quote2;
  useEffect(() => {
    if (Object.keys(identifiedForm2).length !== 0) {
      setBag(identifiedForm2);
      if (identifiedForm2.box.value) {
        setItems((oldArray) => [...oldArray, "Box"]);
      }
      if (identifiedForm2.authenticityCard.value) {
        setItems((oldArray) => [...oldArray, "Authenticity Card"]);
      }
      if (identifiedForm2.shoulderStrap.value) {
        setItems((oldArray) => [...oldArray, "Shoulder Strap"]);
      }
      if (identifiedForm2.dustBag.value) {
        setItems((oldArray) => [...oldArray, "Dustbag"]);
      }
      if (identifiedForm2.pouch.value) {
        setItems((oldArray) => [...oldArray, "Pouch"]);
      }
      if (identifiedForm2.padlockKey.value) {
        setItems((oldArray) => [...oldArray, "Padlock & Key"]);
      }
      if (identifiedForm2.bagCharm.value) {
        setItems((oldArray) => [...oldArray, "Bag Charm"]);
      }
      if (identifiedForm2.mirror.value) {
        setItems((oldArray) => [...oldArray, "Mirror"]);
      }
    }
  }, [setBag, identifiedForm2]);

  const identifiedImages = form.quoteImages;
  useEffect(() => {
    if (Object.keys(identifiedImages).length !== 0) {
      setImages(identifiedImages);
    }
    setIsLoading(false);
  }, [setImages, identifiedImages]);

  useEffect(() => {
    if (!isLoading && !isCaptchaLoaded) {
      loadCaptchaEnginge(6);
      setIsCaptchaLoaded(true);
    }
  }, [isLoading, isCaptchaLoaded]);

  const history = useHistory();

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const captchaElement = document.getElementById("captcha");
    const captchaValue = captchaElement.value;
    const isCaptchaValid = validateCaptcha(captchaValue);

    if (!isCaptchaValid) {
      setIsLoading(false);
      captchaElement.value = "";
      captchaElement.focus();
      return;
    }

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0").toString();
    const mm = String(today.getMonth() + 1)
      .padStart(2, "0")
      .toString();
    const yyyy = today.getFullYear().toString().substr(-2);
    const randomNumber = Math.floor(1000 + Math.random() * 9000).toString();
    const time = yyyy + mm + dd + randomNumber;

    const externalRef = `RS${time}`;

    const listLinks = Object.entries(images).reduce((acc, [, image]) => {
      if (image.value) {
        return [...acc, image.value];
      }
      return acc;
    }, []);

    const brandID = brandMapping(bag.brand.value);
    const sizeID = sizesMapping(bag.size.value);
    const conditionID = conditionsMapping(bag.condition.value);
    const country = countryMapping(personal.country.value);

    const bagStyle = bag.style.value.trim();
    const requestDetails = {
      externalId: externalRef,
      externalUserId: "",
      externalReference: externalRef,
      countryIsoCode: country.code,
      customerInformation: {
        name: personal.firstName.value,
        lastName: personal.lastName.value,
        email: personal.emailAddress.value,
        address: {
          country: country.code,
        },
        telephoneNumber: {
          countryCode: country.dialCode,
          number: personal.phoneNumber.value,
        },
      },
      items: [
        {
          externalId: externalRef,
          size: sizeID,
          conditions: conditionID,
          category: {
            id: "ba6f12dd-fa70-4235-94de-e5ba5a9c4b2b",
          },
          brand: {
            id: brandID,
          },
          description: formState.inputs.additionalInformation.value,
          model: bagStyle || "-",
          assets: listLinks,
          extras: items,
        },
      ],
    };

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestDetails),
    };

    try {
      const response = await fetch("/submissions/create/", options);

      if (response.status === 503 && response.json().then((data) => data.reason === "maintenance")) {
        window.location.href = '/Maintenance';
			} else if (response.status >= 400) {
        ReactGa.event({
          category: "Submit",
          action: "Submission Failed",
        });
        setIsLoading(false);
        showError(true);
      } else {
        ReactGa.event({
          category: "Submit",
          action: "Successful Submission",
        });
        form.submissionIDHandler(externalRef);
        setIsLoading(false);
        history.push("/confirmation");
        form.clean();
      }
    } catch (err) {
      ReactGa.event({
        category: "Submit",
        action: "Submission Failed",
      });
      setIsLoading(false);
      showError(true);
    }
  };

  const closeModalError = (event) => {
    event.preventDefault();
    showError(false);
  };

  if (isLoading) {
    return (
      <div className="text-align-center">
        <br />
        <br />
        <img
          src={loadingSVG}
          width="30px"
          height="30px"
          alt="loading"
          className="text-align-center spin"
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal show={error} onCancel={closeModalError} className="top-10-vh">
        <ContainerFluid className="p-t-75 p-b-82 ">
          <div className="text-align-center margin-121">
            <p className="does-title padding-b-32">
              Something went wrong. Please submit again or contact our customer
              service
            </p>
          </div>
        </ContainerFluid>
      </Modal>
      <Container className="padding-small">
        <QuoteCreationHeader third="true" />
        <div className="center max-width-684">
          <p className="details letter-spacing-1 padding-b-24">
            <b>Review & Confirm</b>
          </p>

          <div className="d-flex padding-b-30">
            <p className="yellowSquare " />
            <p className="titleDetails margin-l-3">Your Details</p>
          </div>

          <div className="row">
            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">First Name</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{personal.firstName.value}</p>
            </div>

            <div className="col-sm-4" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Last Name</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{personal.lastName.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Email</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{personal.emailAddress.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Country</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{personal.country.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Mobile Number</p>
            </div>
            <div className="col-sm-4 padding-b-10  mobile-6">
              <p className="details">{personal.phoneNumber.value}</p>
            </div>

            <div className="col-sm-4 d-flex">
              <Button
                to="/QuoteCreation"
                className="background-secondary ml-auto align-self-start"
                action="Personal Details Review"
                category="review page button"
              >
                Edit
              </Button>
            </div>
          </div>

          <hr className="grey" />

          <div className="d-flex padding-b-30 padding-t-32">
            <p className="yellowSquare " />
            <p className="titleDetails margin-l-3">Your Bag Details</p>
          </div>

          <div className="row">
            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center ">Category</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">Bags</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center ">Brand</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.brand.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center ">Style</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.style.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center ">Condition</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.condition.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Size</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.size.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Material</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.material.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Colour</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{bag.colour.value}</p>
            </div>

            <div className="col-sm-4 removeWhenMobile" />

            <div className="col-sm-4 padding-b-10 mobile-spec">
              <p className="noteReview align-self-center">Extras</p>
            </div>
            <div className="col-sm-4 padding-b-10 mobile-6">
              <p className="details">{items.toString()}</p>
            </div>

            <div className="col-sm-4 d-flex">
              <Button
                to="/QuoteCreation2"
                className="background-secondary ml-auto align-self-start lh-40"
                action="Bag Details Review"
                category="review page button"
              >
                Edit
              </Button>
            </div>
          </div>

          <hr className="grey" />

          <div className="d-flex padding-b-30 padding-t-32">
            <p className="yellowSquare " />
            <p className="titleDetails margin-l-3">Attached photos</p>
          </div>

          <div className="d-flex flex-wrap center-flex">
            {images.frontImage && images.frontImage.value !== "" ? (
              <img
                src={images.frontImage.value}
                className="squarePicture"
                alt="front "
              />
            ) : (
              ""
            )}
            {images.backImage && images.backImage.value !== "" ? (
              <img
                src={images.backImage.value}
                className="squarePicture"
                alt="back "
              />
            ) : (
              ""
            )}
            {images.bottomImage && images.bottomImage.value !== "" ? (
              <img
                src={images.bottomImage.value}
                className="squarePicture"
                alt="bottom "
              />
            ) : (
              ""
            )}
            {images.insideImage && images.insideImage.value !== "" ? (
              <img
                src={images.insideImage.value}
                className="squarePicture"
                alt="inside "
              />
            ) : (
              ""
            )}
            {images.interiorLogoImage &&
            images.interiorLogoImage.value !== "" ? (
              <img
                src={images.interiorLogoImage.value}
                className="squarePicture"
                alt="interior "
              />
            ) : (
              ""
            )}
            {images.labelImage && images.labelImage.value !== "" ? (
              <img
                src={images.labelImage.value}
                className="squarePicture"
                alt="label "
              />
            ) : (
              ""
            )}
            {images.serialImage && images.serialImage.value !== "" ? (
              <img
                src={images.serialImage.value}
                className="squarePicture"
                alt="serial "
              />
            ) : (
              ""
            )}
            {images.hardwareImage && images.hardwareImage.value !== "" ? (
              <img
                src={images.hardwareImage.value}
                className="squarePicture"
                alt="hardware "
              />
            ) : (
              ""
            )}
            {images.zipperImage && images.zipperImage.value !== "" ? (
              <img
                src={images.zipperImage.value}
                className="squarePicture"
                alt="zipper "
              />
            ) : (
              ""
            )}
            {images.damageImage && images.damageImage.value !== "" ? (
              <img
                src={images.damageImage.value}
                className="squarePicture"
                alt="damage"
              />
            ) : (
              ""
            )}
          </div>
          <div className=" d-flex pt-15">
            <Button
              to="/QuoteCreation2"
              className="background-secondary secondPanel ml-auto lh-40"
              action="Image Review"
              category="review page button"
            >
              Edit
            </Button>
          </div>

          <hr className="grey" />
          <div className="d-flex padding-b-30 padding-t-32">
            <p className="yellowSquare " />
            <p className="titleDetails margin-l-3">
              Please tell us any information you think might be helpful to the
              valuation experts, including any damage or wear and tear.
            </p>
          </div>
          {/* commented code to not submit*/}
          {/* <form onSubmit={submitHandler}> */}
          <form>
            <div className="padding-b-95">
              <Input
                element="textarea"
                id="additionalInformation"
                type="text"
                label=""
                validators={[MAXLENGTH(450)]}
                placeholder=""
                errorText="This field is only limited to 450 characters"
                onInput={inputHandler}
                rows="10"
                initialValue={formState.inputs.additionalInformation.value}
                initialValid={formState.inputs.additionalInformation.isValid}
              />
            </div>

            <div className="padding-b-66">
              <LoadCanvasTemplateNoReload />

              <Input
                element="input"
                type="text"
                label=""
                id="captcha"
                placeholder="Insert the characters you see in the image above"
                validators={[NONE()]}
              />
            </div>

            <div className="padding-b-66">
              <Checkbox
                label={
                  <div>
                    By completing this form and ticking this box, you understand
                    your information will be shared with our valuation partner
                    to help authenticate your bag, and you have read and
                    understand our &nbsp;
                    <a href="/TermsConditions" target="_blank">
                      <b>
                        <u>Terms & Conditions</u>
                      </b>
                    </a>{" "}
                    and &nbsp;
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.selfridges.com/GB/en/features/info/our-corporate-policies/privacy-cookie-policy/"
                    >
                      <b>
                        <u>Privacy & Cookie Policy</u>
                      </b>
                    </a>
                    .
                  </div>
                }
                id="termsCondition"
                onInput={inputHandler}
                initialValue={formState.inputs.termsCondition.value}
                initialValid={formState.inputs.termsCondition.isValid}
              />
            </div>

            <div className="text-align-center">
              {/* <Button
                className="background-primary"
                disabled={
                  !formState.isValid || !formState.inputs.termsCondition.value
                }
              >
                Submit Your Bag
              </Button> */}
            </div>
          </form>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default QuoteCreation3;
