import React from 'react';
import lightExterirorScratches from '../../assets/images/ConditionGuidelines/Accepted/LightExteriorScratches/light exterior scratch.jpg';
import lightInteriorScratches from '../../assets/images/ConditionGuidelines/Accepted/LightInteriorScratches/Light interior scratch.jpg';
import lightWaterStain from '../../assets/images/ConditionGuidelines/Accepted/LightWaterStains/light water stains.jpg';
import slightlyDiscolouration from '../../assets/images/ConditionGuidelines/Accepted/SlightDiscolouration/Slight Discoloration.jpg';
import slightlyExteriorDirt from '../../assets/images/ConditionGuidelines/Accepted/SlightExteriorDirt/Slight exterior dirt.jpg';
import slightlyCrumpledExterior from '../../assets/images/ConditionGuidelines/Accepted/SlightlyCrumpledExterior/slightly crumpled.jpg';
import slightlyDeformedHandle from '../../assets/images/ConditionGuidelines/Accepted/SlightlyDeformedHandle/slightly deformed handle.jpg';
import slightlyFadedHardware from '../../assets/images/ConditionGuidelines/Accepted/SlightlyFadedHardware/Slight faded hardware.jpg';
import slightlyOutOfShape from '../../assets/images/ConditionGuidelines/Accepted/SlightlyOutofShape/slightly out of shape.jpg';
import damagedHardware from '../../assets/images/ConditionGuidelines/Notaccepted/DamagedHardware/Screenshot 2020-10-08 at 09.36.31.png';
import discolouration from '../../assets/images/ConditionGuidelines/Notaccepted/Discolouration/FF0GUCX204/12810356732_image.jpg';
import looseStitching from '../../assets/images/ConditionGuidelines/Notaccepted/LooseStitching/FF0PRSH010/12658323239_EBBD0D0B-EFE5-4C1D-BFCC-6462500CD9C3.jpeg';
import missingHardware from '../../assets/images/ConditionGuidelines/Notaccepted/MissingHardware/missing hardware.jpg';
import missingStitches from '../../assets/images/ConditionGuidelines/Notaccepted/MissingStitches/Missing Stitches.jpg';
import missingZipper from '../../assets/images/ConditionGuidelines/Notaccepted/MissingZipper/Missing Zipper.jpg';
import peeledHandle from '../../assets/images/ConditionGuidelines/Notaccepted/PeeledHandle/FF0MMST059/12809685586_inbound6592510181890784396.jpg';
import peeledInterior from '../../assets/images/ConditionGuidelines/Notaccepted/PeeledInterior/FF0BGST106/12804949218_image.jpg';
import rippedHandle from '../../assets/images/ConditionGuidelines/Notaccepted/RippedHandle/Ripped Handle.jpg';
import rippedInterior from '../../assets/images/ConditionGuidelines/Notaccepted/RippedInterior/FF0LVCX152/12811689676_image.jpg';
import severeLooseStitching from '../../assets/images/ConditionGuidelines/Notaccepted/SevereLooseStitching/Severe Loose Stitching.jpg';
import shreddedHandle from '../../assets/images/ConditionGuidelines/Notaccepted/ShreddedHandle/Screenshot 2020-10-08 at 17.51.32.png';
import tornCorners from '../../assets/images/ConditionGuidelines/Notaccepted/TornCorners/FF0PRST165/12813160968_D2283FB1-8AFF-4385-BFBE-3249D3540777.jpeg';
import tornStitching from '../../assets/images/ConditionGuidelines/Notaccepted/TornStitching/Torn Stitches.jpg';
import transferOfColour from '../../assets/images/ConditionGuidelines/Notaccepted/TransferofColour/FF9FNTO015/FF9FNTO015_5.jpg';
import Button from '../../shared/components/UIElements/Button';
import Container from '../../shared/components/UIElements/ContainerFluid';
import Modal from '../../shared/components/UIElements/Modal';
import './ModalCondition.css';

const ModalGuideline = props => {
	return (
		<Modal
			show={props.show}
			onCancel={props.onCancel}
		>
			<Container className="p-t-75 p-b-48 height-overflow">
				<div className="text-align-center">
					<h1 className="title-resellfridges">RESELFRIDGES</h1>
					<p className="condition p-b-48">Submission guidelines</p>
				</div>

				<div className="margin-114">
					<div className="p-b-46 text-align-center">
						<p className="mute">Please note that if the photos you provide don&apos;t accurately represent your bag this could mean we are unable to accept your bag. If this occurs, we will return your item to your pick-up address, free of charge.</p>
					</div>

					<div className="d-flex p-b-20">
						<p className="yellowSquare align-self-center"></p>
						<p className="what-accept align-self-center margin-l-3"><b>WHAT WE&apos;LL ACCEPT</b></p>
					</div>

					<p className="mute p-b-14">We’ll accept your bag in the following condition:</p>

					<p className="mute p-b-32">
                        - Minor stains on the interior or exterior<br />
                        - Slight discolouration<br />
                        - Faded/tarnished hardware<br />
                        - Minor scratches on exterior or interior<br />
                        - Items that have slightly lost their structure<br />
                        - Watermark stains on leather/material <br />
                        - Loose threads<br />
                        - Slightly deformed handle
					</p>

					<div className="row p-b-13">
						<div className="col-sm-4 p-b-28">
							<div className="photosModal">

								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={lightExterirorScratches} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Minor Exterior Scratches</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={lightInteriorScratches} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Minor Interior Scratches</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={lightWaterStain} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Minor Water Stains</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={slightlyDiscolouration} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slight Discolouration</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={slightlyExteriorDirt} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slight Exterior Dirt</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">

								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>
								<img src={slightlyCrumpledExterior} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slightly Crumpled Exterior</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">

								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>
								<img src={slightlyDeformedHandle} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slightly Deformed Handle</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>

								<img src={slightlyFadedHardware} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slightly Faded Hardware</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="checkmarks">
									<svg version="1.1" id="Layer_1" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" >
										<circle className="circle-svg" fill="#85B85B" stroke="#85B85B" strokeWidth="7" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"></circle>
										<polyline className="icon-svg" fill="none" stroke="white" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
									</svg>
								</div>
								<img src={slightlyOutOfShape} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Slightly Out of Shape</p>
						</div>
					</div>

					<div className="d-flex p-b-20">
						<p className="yellowSquare align-self-center"></p>
						<p className="what-accept align-self-center margin-l-3"><b>WHAT WE DON&apos;T ACCEPT</b></p>
					</div>

					<p className="mute p-b-14">We don&apos;t accept items in the following condition:</p>

					<p className="mute p-b-32">
                        - Major loose or missing stitching<br />
                    - Major torn/ripped material<br />
                    - Peeled or cracked leather/material on interior, exterior or handle<br />
                    - Deformed handles <br />
                    - Extreme discolouration/staining<br />
                    - Holes in material<br />
                    - Ripped handles or torn material on handles<br />
                    - Fabric run, damaged zippers<br />
                    - Bags with any form of personalisation<br />
					</p>

					<p className="mute p-b-32">
                        We also won’t accept bags from:<br />
						<br />
                    - Designer outlets<br />
                    - Employee sales<br />
                    - Press samples<br />
					</p>

					<div className="row p-b-13">
						<div className="col-sm-4 p-b-28">
							<div className="photosModal">

								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={discolouration} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Discolouration</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={looseStitching} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Loose Stitching</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={missingStitches} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Missing stitches</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={missingZipper} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Missing Zipper</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={peeledHandle} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Peeled Handle</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={peeledInterior} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Peeled Interior</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={rippedHandle} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Ripped Handle</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={rippedInterior} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Ripped Interior</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={severeLooseStitching} width="100%" height="100%" alt="damge" className="image-object-fit" />

							</div>
							<p className="mute">Severe Loose Stitching</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={shreddedHandle} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Shredded Handle</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={tornCorners} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Torn Corners</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={tornStitching} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Torn Stitching</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={damagedHardware} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Damaged Hardware</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={missingHardware} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Missing Hardware</p>
						</div>

						<div className="col-sm-4 p-b-28">
							<div className="photosModal center">
								<div className="box-circle">
									<svg className="close-x" viewBox="0 0 20 20">
										<path d="M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
									</svg>
								</div>
								<img src={transferOfColour} width="100%" height="100%" alt="damge" className="image-object-fit" />
							</div>
							<p className="mute">Transfer of Colour</p>
						</div>
					</div>

				</div>

			</Container>

			<div className="text-align-center p-b-110">
				<Button className="background-secondary" onClick={props.onCancel}>
                    Close
				</Button>

			</div>
		</Modal>
	);
};

export default ModalGuideline;
